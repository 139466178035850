import App from 'next/app'
import type { AppProps, AppContext } from 'next/app'
import Image from 'next/image'
import { SessionProvider } from 'next-auth/react'
import { MDXProvider } from '@mdx-js/react'
import type { MDXComponents } from 'mdx/types'

import '@/styles/globals.scss'

const components: MDXComponents = {
  img: ({ alt, src, ...rest }) => (
    <Image alt={alt} layout="responsive" src={src!} {...(rest as any)} />
  )
}

function MyApp(props: AppProps<ft.GlobalPageProps>) {
  const { Component, pageProps } = props

  return (
    <MDXProvider components={components}>
      <SessionProvider session={pageProps.session} refetchInterval={0}>
        <Component {...pageProps} />
      </SessionProvider>
    </MDXProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const props = await App.getInitialProps(appContext)

  return {
    ...props
  }
}

export default MyApp
